body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: hidden;
}

#root {
  height: 100%;
}

body {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0,0,0,.8);
  line-height: 1.5;
  height: 100vh;
  letter-spacing: 0.53px;
  margin-right: -1px !important;
}

html, body, p, a, h1, h2, h3, h4, ul, pre, code {
  margin: 0;
  padding: 0;
  color: inherit;
}

a:active, a:focus, button:focus, input:focus {
  outline: none;
}

input, button {
  border: none;
}

input, button, pre {
  font-family: 'Open Sans', sans-serif;
}

code {
  font-family: Consolas, monospace;
}

table {
  text-align: left;
  width: 100%;
  border: 1px solid #fff;
}

tr {
  width: 100%;
  border: 1px solid #fff;
}

td {
  border: 1px solid #fff;
  color: #fff;
}

th {
  width: 100px;
  border: 1px solid #fff;
  color: #fff;
}
